import { useState } from "react"

export function SmartphoneOwnershipTable() {
    const data = [
        {
            country: "United States",
            years: ["296M", "302M", "308M", "313M", "319M", "324M"],
            source: "Statista",
            url: "https://www.statista.com/statistics/201182/forecast-of-smartphone-users-in-the-us/",
        },
        {
            country: "China",
            years: ["986M", "1.02B", "1.05B", "1.08B", "1.11B", "1.13B"],
            source: "Newzoo",
            url: "https://newzoo.com/insights/markets/china/",
        },
        {
            country: "India",
            years: ["900M", "940M", "980M", "1.01B", "1.05B", "1.08B"],
            source: "TechSci Research",
            url: "https://www.techsciresearch.com/news/7255-smartphone-market-in-india.html",
        },
        {
            country: "Brazil",
            years: ["160M", "165M", "169M", "174M", "178M", "182M"],
            source: "Statista",
            url: "https://www.statista.com/statistics/276668/number-of-smartphone-users-in-brazil/",
        },
        {
            country: "Indonesia",
            years: ["202M", "208M", "215M", "221M", "227M", "233M"],
            source: "Newzoo",
            url: "https://newzoo.com/insights/markets/indonesia/",
        },
        // Add more country data here as needed
    ]

    return (
        <div style={{ overflowX: "auto", width: "100%" }}>
            <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                    <tr>
                        <th style={styles.th}>Country</th>
                        <th style={styles.th}>2024</th>
                        <th style={styles.th}>2025</th>
                        <th style={styles.th}>2026</th>
                        <th style={styles.th}>2027</th>
                        <th style={styles.th}>2028</th>
                        <th style={styles.th}>2029</th>
                        <th style={styles.th}>Source</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td style={styles.td}>{item.country}</td>
                            {item.years.map((year, i) => (
                                <td key={i} style={styles.td}>
                                    {year}
                                </td>
                            ))}
                            <td style={styles.td}>
                                <a
                                    href={item.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    {item.source}
                                </a>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

const styles = {
    th: {
        padding: "8px",
        backgroundColor: "#f2f2f2",
        border: "1px solid #ddd",
        textAlign: "left",
    },
    td: {
        padding: "8px",
        border: "1px solid #ddd",
        textAlign: "left",
    },
}
